import { Box, Button, LoadPanel, ScrollView, Toolbar } from "devextreme-react";
import { Item } from "devextreme-react/box";
import DataGrid, {
    AsyncRule,
    Column,
    ColumnFixing,
    CustomRule,
    Editing,
    HeaderFilter,
    Lookup,
    Pager,
    Paging,
    PatternRule,
    Popup,
    RangeRule,
    RequiredRule,
    SearchPanel,
} from "devextreme-react/data-grid";
import { Item as ToolbarItem } from "devextreme-react/toolbar";
import ArrayStore from "devextreme/data/array_store";
import { dxDataGridColumn } from "devextreme/ui/data_grid";
import { confirm } from "devextreme/ui/dialog";
import notify from "devextreme/ui/notify";
import parse from "html-react-parser";
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { ASSET_DATA_FIELDS } from "../constants/assetDataFIelds";
import { FORMAT } from "../constants/format";
import { LEASE_TERM_PARAMS } from "../constants/leaseTermParams";
import { YEAR_RANGE } from "../constants/yearRange";
import { assetCodingClassStore } from "../stores/assetCodingClassStore";
import { assetEntryServiceLifeStore } from "../stores/assetEntryServiceLivesStore";
import { ASSETS_URL } from "../stores/assetStore";
import { assetTypeStore } from "../stores/assetTypeStore";
import { studyRoomStore } from "../stores/studyRoomStore";
import { studyStructureStore } from "../stores/studyStructureStore";
import { unitOfMeasureStore } from "../stores/unitOfMeasureStore";
import { AssetServiceLife } from "../types/assetServiceLife";
import { NullableAsset } from "../types/nullableAsset";
import { ToolbarPreparingEventingInternal } from "../types/toolbarPreparingEventInternal";
import { calculateAggregateReplacementValue } from "../utility functions/calculateAggregateReplacementValue";
import { calculateLeaseEndValue } from "../utility functions/calculateLeaseEndValue";
import { calculateLeaseTotalPayment } from "../utility functions/calculateLeaseTotalPayment";
import { calculateLeaseYearlyPayment } from "../utility functions/calculateLeaseYearlyPayment";

const allowedPageSizes = [10, 20, 50, 100];
const defaultPageSize = 10;

const thousandsAndDecimalEditorOptions = {
    format: FORMAT.THOUSANDS_AND_DECIMAL,
};
const wholeNumberEditorOptions = { format: FORMAT.WHOLE_NUMBER };

const WHOLE_NUMBER_PATTERN = "^\\d+$";

const DEFAULT_MINIMUM_ASSET_LIFECYCLE = 0;
const DEFAULT_MAXIMUM_ASSET_LIFECYCLE = 100;
const ONE_CENT = 0.01;

type AssetServiceLifeParams = {
    max: number;
    min: number;
    auto: number | null;
};

type AsyncValidationResult = {
    isValid: boolean;
    message: string | undefined;
};

enum ChangeType {
    Insert = "insert",
    Update = "update",
    Delete = "delete",
}

interface DataChange<DataType> {
    data: Partial<DataType>;
    key: number | null;
    type: ChangeType;
}

interface LoadPanelState {
    visible: boolean;
    message: string;
}

interface ProcessResult {
    isSuccess: boolean;
    errorMessage: string;
}

type MinRange = { MIN: number; MAX: undefined };
type MaxRange = { MIN: undefined; MAX: number };
type MinMaxRange = { MIN: number; MAX: number };

const createRangeRuleMessage = (
    fieldName: string,
    range: MinRange | MaxRange | MinMaxRange
) => {
    let message = `${fieldName} needs to be `;

    if (range.MIN !== undefined && range.MAX !== undefined) {
        message += `between ${range.MIN} and ${range.MAX}.`;
    } else if (range.MIN !== undefined) {
        message += `greater than ${range.MIN}.`;
    } else if (range.MAX !== undefined) {
        message += `less than ${range.MAX}.`;
    }

    return message;
};

const handleErrors = (response: Response) => {
    if (!response.ok) throw Error(response.statusText);
    return response;
};

const DEFAULT_LOADING_PANEL_STATE: LoadPanelState = {
    visible: false,
    message: "",
};

const MIN_WARN = 20;

export const AssetBulkEdit = () => {
    const dataGrid = useRef<DataGrid>(null);
    const [assets, setAssets] = useState<NullableAsset[]>([]);
    const [workingAssets, setWorkingAssets] = useState<NullableAsset[]>([]);
    const [processResults, setProcessResults] = useState<string | undefined>(
        undefined
    );
    const [loadPanelState, setLoadPanelState] = useState<LoadPanelState>(
        DEFAULT_LOADING_PANEL_STATE
    );

    const getFilteredRooms = (options: { data: NullableAsset }) => {
        return {
            store: studyRoomStore,
            filter: options.data
                ? [
                      ASSET_DATA_FIELDS.STUDY_STRUCTURE_ID,
                      "=",
                      options.data.studyStructureId,
                  ]
                : null,
        };
    };

    const setStructureValue = (rowData: NullableAsset, value: number) => {
        rowData.studyRoomId = null;
        rowData.studyStructureId = value;
    };

    const setUnitReplacmentCellValue = (
        rowData: NullableAsset,
        value: number,
        currentData: NullableAsset
    ) => {
        rowData.aggregateReplacementValue = calculateAggregateReplacementValue(
            currentData.qty,
            value
        );
        rowData.unitReplacementValue = value;
    };

    const setQtyCellValue = (
        rowData: NullableAsset,
        value: number,
        currentData: NullableAsset
    ) => {
        rowData.aggregateReplacementValue = calculateAggregateReplacementValue(
            value,
            currentData.unitReplacementValue
        );
        rowData.qty = value;
    };

    const setInitialRYManualValue = (
        newData: NullableAsset,
        value: number | null
    ) => {
        newData.initialRYManual = value;
    };

    const setLeaseStatusValue = (newData: NullableAsset, value: boolean) => {
        newData.isLeased = value;
    };

    const setLeaseStartCellValue = (
        rowData: NullableAsset,
        value: Date | null,
        currentData: NullableAsset
    ) => {
        rowData.leaseEnd = calculateLeaseEndValue(value, currentData.leaseTerm);
        rowData.leaseStart = value;
    };

    const setLeaseTermCellValue = (
        rowData: NullableAsset,
        value: number | null,
        currentData: NullableAsset
    ) => {
        rowData.leaseEnd = calculateLeaseEndValue(
            currentData.leaseStart,
            value
        );
        rowData.leaseTotalPayment = calculateLeaseTotalPayment(
            currentData.leaseMonthlyPayment,
            value
        );
        rowData.leaseTerm = value || null; //Do not allow zero but default to null.
    };

    const setLeaseMonthlyPaymentCellValue = (
        rowData: NullableAsset,
        value: number | null,
        currentData: NullableAsset
    ) => {
        rowData.leaseTotalPayment = calculateLeaseTotalPayment(
            value,
            currentData.leaseTerm
        );
        rowData.leaseYearlyPayment = calculateLeaseYearlyPayment(value);
        rowData.leaseMonthlyPayment = value || null; //Do not allow zero but default to null.
    };

    function setDollarBuyoutCellValue(
        rowData: NullableAsset,
        value: number | null
    ) {
        rowData.leaseDollarBuyoutAmount = value || null; //Do not allow zero but default to null.
    }

    const loadAssetsAsync = useCallback(async (loadingMessage: string) => {
        setLoadPanelState({ visible: true, message: loadingMessage });
        return await fetch(ASSETS_URL, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then(handleErrors)
            .then((response) => response.json())
            .then((assets) => {
                setAssets(assets);
                setWorkingAssets(assets);
            })
            .catch(() => {
                notify(
                    {
                        message: `An error occured while attempting to load the assets.`,
                        position: {
                            my: "center top",
                            at: "center top",
                        },
                    },
                    "error",
                    5000
                );
            })
            .finally(() => {
                setLoadPanelState(DEFAULT_LOADING_PANEL_STATE);
            });
    }, []);

    useEffect(() => {
        loadAssetsAsync("loading");
    }, [loadAssetsAsync]);

    const setYearInstalledCellValue = (
        rowData: NullableAsset,
        value: number | null,
        currentData: NullableAsset
    ) => {
        const assetLifeCycle = currentData.assetLifeCycle;
        rowData.yearInstalled = value;
        rowData.initialRYAuto =
            value && assetLifeCycle ? value + assetLifeCycle : null;
    };

    const setAssetLifeCycleCellValue = (
        rowData: NullableAsset,
        value: number | null,
        currentData: NullableAsset
    ) => {
        const yearInstalled = currentData.yearInstalled;
        rowData.assetLifeCycle = value;
        rowData.initialRYAuto =
            value && yearInstalled ? value + yearInstalled : null;
    };

    const updateAllRowsByKeyValuePair = async <Key extends keyof NullableAsset>(
        key: Key,
        value?: NullableAsset[Key]
    ) => {
        const dataGridInstance = dataGrid.current?.instance;
        if (dataGridInstance) {
            const combinedFilter = dataGridInstance.getCombinedFilter();

            const dataStore = dataGridInstance
                .getDataSource()
                .store() as ArrayStore;

            const filteredAssets = (await dataStore.load({
                filter: combinedFilter,
            })) as NullableAsset[];

            setWorkingAssets((current) =>
                current.map((a) => {
                    //If asset is not included in the current filter return as-is
                    if (filteredAssets.findIndex((fa) => fa.id === a.id) === -1)
                        return a;

                    //Set all auto-calculated values
                    let ryAuto = a.initialRYAuto;
                    let arv = a.aggregateReplacementValue;
                    let leaseEnd = a.leaseEnd;
                    let leaseTotalPayment = a.leaseTotalPayment;
                    let leaseYearlyPayment = a.leaseYearlyPayment;

                    switch (key) {
                        case ASSET_DATA_FIELDS.YEAR_INSTALLED:
                            ryAuto =
                                value && a.assetLifeCycle
                                    ? (value as number) + a.assetLifeCycle
                                    : null;
                            break;
                        case ASSET_DATA_FIELDS.ASSET_LIFE_CYCLE:
                            ryAuto =
                                value && a.yearInstalled
                                    ? (value as number) + a.yearInstalled
                                    : null;
                            break;
                        case ASSET_DATA_FIELDS.UNIT_REPLACEMENT_VALUE:
                            arv = calculateAggregateReplacementValue(
                                a.qty,
                                value as number | null | undefined
                            );
                            break;
                        case ASSET_DATA_FIELDS.QTY:
                            arv = calculateAggregateReplacementValue(
                                a.qty,
                                value as number | null | undefined
                            );
                            break;
                        case ASSET_DATA_FIELDS.LEASE_START:
                            leaseEnd = calculateLeaseEndValue(
                                value as Date | null | undefined,
                                a.leaseTerm
                            );
                            break;
                        case ASSET_DATA_FIELDS.LEASE_TERM:
                            leaseEnd = calculateLeaseEndValue(
                                a.leaseStart,
                                value as number | null | undefined
                            );
                            leaseTotalPayment = calculateLeaseTotalPayment(
                                a.leaseMonthlyPayment,
                                value as number | null | undefined
                            );
                            break;
                        case ASSET_DATA_FIELDS.LEASE_MONTHLY_PAYMENT:
                            leaseTotalPayment = calculateLeaseTotalPayment(
                                value as number | null | undefined,
                                a.leaseTerm
                            );
                            leaseYearlyPayment = calculateLeaseYearlyPayment(
                                value as number | null | undefined
                            );
                            break;
                        default:
                            break;
                    }

                    return {
                        ...a,
                        initialRYAuto: ryAuto,
                        aggregateReplacementValue: arv,
                        leaseEnd: leaseEnd,
                        leaseTotalPayment: leaseTotalPayment,
                        leaseYearlyPayment: leaseYearlyPayment,
                        [key]: value,
                    };
                })
            );
        }
    };

    const onCellDblClick = async (e: {
        value?: any;
        column?: dxDataGridColumn;
        rowType?: string;
    }) => {
        const { value, rowType, column } = e;
        if (column && column?.allowEditing && rowType === "data") {
            const dataField = e.column?.dataField as keyof NullableAsset;
            updateAllRowsByKeyValuePair(dataField, value);
        }
    };

    const changes = useMemo(() => {
        const current: DataChange<NullableAsset>[] = workingAssets.map(
            (working) => {
                const existing = assets.find((ex) => ex.id === working.id);

                let changedData: Partial<NullableAsset> = {};

                if (existing) {
                    const keys = Object.keys(existing);

                    for (const key of keys) {
                        const dataField = key as keyof NullableAsset;
                        const existingValue = existing[dataField];
                        const workingValue = working[dataField];
                        if (existingValue !== workingValue) {
                            Object.assign(changedData, {
                                [dataField]: workingValue,
                            });
                        }
                    }
                }

                return {
                    data: changedData,
                    type: ChangeType.Update,
                    key: working.id as number,
                };
            }
        );

        return current.filter((c) => Object.keys(c.data).length !== 0);
    }, [assets, workingAssets]);

    // It is essential that usecallback is used here.
    // If it is not, this will be triggered twice and result in an infinite callstack.
    const onChangesChange = useCallback(
        async (changed: DataChange<NullableAsset>[]) => {
            setWorkingAssets((current) =>
                current.map((a) => {
                    const foundChange = changed.find((c) => c.key === a.id);
                    return foundChange ? { ...a, ...foundChange.data } : a;
                })
            );
        },
        []
    );

    const getAssetLifeCycleParams = async (assetServiceLifeId: number) => {
        const values: AssetServiceLifeParams = {
            min: DEFAULT_MINIMUM_ASSET_LIFECYCLE,
            max: DEFAULT_MAXIMUM_ASSET_LIFECYCLE,
            auto: null,
        };

        if (assetServiceLifeId) {
            const assetServiceLife = (await assetEntryServiceLifeStore.byKey(
                assetServiceLifeId
            )) as AssetServiceLife;
            if (!assetServiceLife) return values;
            const assetServiceLifeTextValue = assetServiceLife.name;
            if (assetServiceLifeTextValue.startsWith("to")) {
                const assetLifeCycle = parseInt(
                    assetServiceLifeTextValue.replace("to", "").trim()
                );

                values.min = assetLifeCycle;
                values.max = assetLifeCycle;
                values.auto = assetLifeCycle;
            } else {
                const minMaxRange = assetServiceLifeTextValue.split("to");
                minMaxRange.sort(function (x, y) {
                    // Sort list
                    return parseInt(x) - parseInt(y);
                });
                let [minimumLifeCycle, maximumLifecycle] = [
                    minMaxRange[0],
                    minMaxRange[1],
                ];

                values.min = parseInt(minimumLifeCycle);
                values.max = parseInt(maximumLifecycle);
            }
        }

        return values;
    };

    const resetEditorAsync = useCallback(async () => {
        await loadAssetsAsync("Reloading");
        setProcessResults(undefined);
    }, [loadAssetsAsync]);

    const handleRevertClicked = useCallback(async () => {
        await resetEditorAsync();
    }, [resetEditorAsync]);

    const handleSaveClicked = useCallback(async () => {
        const changedKeys = changes.map((c) => c.key);

        const confirmResult =
            changedKeys.length > MIN_WARN
                ? confirm(
                      `<i>You are about to updated more than ${MIN_WARN} assets. Are you sure?</i>`,
                      "Confirm Save"
                  )
                : Promise.resolve(true);

        const saveAccepted = await confirmResult;

        if (saveAccepted) {
            setProcessResults(undefined);
            setLoadPanelState({
                visible: true,
                message: "Saving...",
            });

            const modifiedAssets = workingAssets.filter((a) =>
                changedKeys.includes(a.id)
            );

            await fetch("api/assetbulk/", {
                method: "PUT",
                body: JSON.stringify(modifiedAssets),
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then(handleErrors)
                .then((result) => result.json())
                .then((results: ProcessResult) => {
                    if (!results.isSuccess) {
                        setProcessResults(results.errorMessage);
                    } else {
                        notify(
                            {
                                message:
                                    "Assets have been updated successfully.",
                                position: {
                                    my: "center top",
                                    at: "center top",
                                },
                            },
                            "success",
                            5000
                        );
                        return resetEditorAsync();
                    }
                })
                .catch((err) => {
                    console.log("err: ", err);
                    notify(
                        {
                            message:
                                "An internal server error occured while attempting to process the bulk edit, please try again",
                            position: {
                                my: "center top",
                                at: "center top",
                            },
                        },
                        "error",
                        5000
                    );
                })
                .finally(() => {
                    setLoadPanelState(DEFAULT_LOADING_PANEL_STATE);
                });
        }
    }, [changes, workingAssets, resetEditorAsync]);

    const validateYearIfNotLeased = (e: {
        value: number;
        data?: NullableAsset;
    }) => {
        const { data, value } = e;
        return (!data?.isLeased || data.isLegacyLease) && value !== null
            ? value >= YEAR_RANGE.MIN && value <= YEAR_RANGE.MAX
            : true;
    };

    const positiveIfNotLeased = (e: {
        value: number;
        data?: NullableAsset;
    }) => {
        const { data, value } = e;
        return (!data?.isLeased || data.isLegacyLease) && value !== null
            ? value >= 0
            : true;
    };

    const validateLastRYCycleAsync = (e: {
        data?: NullableAsset;
        value?: number;
    }) => {
        const { data, value } = e;

        const validatationResult: AsyncValidationResult = {
            isValid: true,
            message: undefined,
        };

        if (value && (!data?.isLeased || data.isLegacyLease)) {
            if (data?.initialRYManual && value < data.initialRYManual) {
                validatationResult.isValid = false;
                validatationResult.message =
                    "The Last RY Cycle cannot be less than Initial RY Manual.";
            } else if (data?.initialRYAuto && value < data.initialRYAuto) {
                validatationResult.isValid = false;
                validatationResult.message =
                    "The Last RY Cycle cannot be less than Initial RY Auto.";
            }
        }

        return Promise.resolve(validatationResult);
    };

    const validateLifeCycleAsync = async (e: {
        data?: NullableAsset;
        value?: number;
    }) => {
        const { data, value } = e;
        const { assetServiceLifeId } = data as NullableAsset;

        const validatationResult: AsyncValidationResult = {
            isValid: true,
            message: undefined,
        };

        if (!assetServiceLifeId) return validatationResult;

        const { min, max } = await getAssetLifeCycleParams(assetServiceLifeId);

        if (value && value > max) {
            validatationResult.isValid = false;
            validatationResult.message = `Value is greater than the maximum value of ${max} by Service Life.`;
        } else if (value && value < min) {
            validatationResult.isValid = false;
            validatationResult.message = `Value is less than the minimum value of ${min} by Service Life.`;
        }

        return Promise.resolve(validatationResult);
    };

    const requiredIfLeased = (e: { value: Date; data?: NullableAsset }) => {
        const { data, value } = e;
        return data?.isLeased && !data.isLegacyLease ? value : true;
    };

    const requiredIfNotLeased = (e: { value: Date; data?: NullableAsset }) => {
        const { data, value } = e;
        return !data?.isLeased || data.isLegacyLease ? value : true;
    };

    const onToolbarPreparing = useCallback(
        (e: ToolbarPreparingEventingInternal) => {
            e.cancel = true;
            if (e.toolbarOptions?.items) {
                const saveButton = e.toolbarOptions.items.find(
                    (i) => i.name === "saveButton"
                );
                if (saveButton && saveButton.options) {
                    saveButton.options.visible = false;
                    saveButton.options.disabled = true;
                }

                const revertButton = e.toolbarOptions.items.find(
                    (i) => i.name === "revertButton"
                );
                if (revertButton && revertButton.options) {
                    revertButton.options.visible = false;
                    revertButton.options.disabled = true;
                }
            }
        },
        []
    );

    const noChangesExist = useMemo(
        () => changes.length === 0,
        [changes.length]
    );

    const handleClearAllFilters = useCallback(() => {
        const dataGridInstance = dataGrid.current?.instance;
        if (dataGridInstance) {
            dataGridInstance.clearFilter();
        }
    }, []);

    return (
        <div className="w-100">
            <LoadPanel
                visible={loadPanelState.visible}
                message={loadPanelState.message}
            />
            <Box width={"100%"}>
                <Item ratio={2}>
                    <div className="p-1">
                        <Toolbar>
                            <ToolbarItem location={"before"}>
                                <Button
                                    onClick={handleClearAllFilters}
                                    stylingMode="outlined"
                                >
                                    <div className="px-1">
                                        Clear All Filters
                                    </div>
                                </Button>
                            </ToolbarItem>
                            <ToolbarItem location={"after"}>
                                <Button
                                    icon="save"
                                    onClick={handleSaveClicked}
                                    disabled={noChangesExist}
                                />
                            </ToolbarItem>
                            <ToolbarItem location={"after"}>
                                <Button
                                    icon="revert"
                                    onClick={handleRevertClicked}
                                    disabled={noChangesExist}
                                />
                            </ToolbarItem>
                        </Toolbar>
                        <DataGrid
                            keyExpr={ASSET_DATA_FIELDS.ID}
                            dataSource={assets}
                            showBorders={true}
                            columnAutoWidth={true}
                            onCellDblClick={onCellDblClick}
                            onToolbarPreparing={onToolbarPreparing}
                            width={"100%"}
                            ref={dataGrid}
                        >
                            <Pager
                                visible={true}
                                showPageSizeSelector={true}
                                allowedPageSizes={allowedPageSizes}
                                showNavigationButtons={true}
                                showInfo={true}
                                infoText="Page {0} of {1} ({2} Assets)"
                            />
                            <Paging defaultPageSize={defaultPageSize} />
                            <Editing
                                mode="batch"
                                allowUpdating={true}
                                changes={changes}
                                onChangesChange={onChangesChange}
                                refreshMode={"repaint"}
                            >
                                <Popup
                                    title="Asset Detail"
                                    showTitle={true}
                                    fullScreen={true}
                                />
                            </Editing>
                            {/* <Selection mode={"multiple"}/> */}
                            <HeaderFilter visible={true} />
                            <SearchPanel visible={true} />
                            <Column
                                dataField={
                                    ASSET_DATA_FIELDS.STRUCTURE_LOCATION_NAME
                                }
                                caption="Structure/Location Name"
                                allowEditing={false}
                                allowSorting={false}
                                allowFiltering={true}
                            />

                            <Column
                                dataField={ASSET_DATA_FIELDS.STUDY_STRUCTURE_ID}
                                setCellValue={setStructureValue}
                                caption="Structure/Location"
                                visible={false}
                                allowEditing={true}
                            >
                                <Lookup
                                    dataSource={studyStructureStore}
                                    valueExpr="id"
                                    displayExpr="name"
                                />
                            </Column>

                            <Column
                                dataField={ASSET_DATA_FIELDS.ROOM_AREA_NAME}
                                caption="Room"
                                allowEditing={false}
                                allowSorting={false}
                                allowFiltering={true}
                            />
                            <Column
                                dataField={ASSET_DATA_FIELDS.STUDY_ROOM_ID}
                                caption="Room/Area"
                                visible={false}
                                allowEditing={true}
                            >
                                <Lookup
                                    dataSource={getFilteredRooms}
                                    valueExpr="id"
                                    displayExpr="name"
                                />
                            </Column>
                            <Column
                                dataField={ASSET_DATA_FIELDS.ASSET_CODING_CLASS}
                                caption="Asset Coding Class"
                                allowEditing={false}
                            >
                                <Lookup
                                    dataSource={assetCodingClassStore}
                                    valueExpr="id"
                                    displayExpr="codeName"
                                    allowClearing={true}
                                />
                                <RequiredRule message="Asset Coding Class is required." />
                            </Column>
                            <Column
                                dataField={ASSET_DATA_FIELDS.ASSET_TYPE_ID}
                                caption="Asset Type"
                            >
                                <Lookup
                                    dataSource={assetTypeStore}
                                    valueExpr="id"
                                    displayExpr="name"
                                />
                                <RequiredRule message="Asset Type is required." />
                            </Column>
                            <Column
                                dataField={ASSET_DATA_FIELDS.IS_LEASED}
                                caption="Lease Status"
                                dataType="boolean"
                                setCellValue={setLeaseStatusValue}
                            />
                            <Column
                                dataField={
                                    ASSET_DATA_FIELDS.ASSET_SUB_TYPE_NAME
                                }
                                caption="Asset Sub-Type"
                            >
                                <RequiredRule message="Asset Sub-Type is required." />
                            </Column>
                            <Column
                                dataField={
                                    ASSET_DATA_FIELDS.ASSET_MANUFACTURER_NAME
                                }
                                caption="Asset Manufacturer"
                            />
                            <Column
                                dataField={ASSET_DATA_FIELDS.DESCRIPTION}
                                caption="Asset Detail"
                            />
                            <Column
                                dataField={ASSET_DATA_FIELDS.ASSET_MODEL_NAME}
                                caption="Asset Model ID"
                            />
                            <Column
                                allowEditing={false}
                                dataField={ASSET_DATA_FIELDS.ASSET_ID}
                                caption="Asset Id"
                                allowSearch={true}
                            />
                            <Column
                                dataField={ASSET_DATA_FIELDS.QTY}
                                caption="Qty"
                                setCellValue={setQtyCellValue}
                                format={FORMAT.WHOLE_NUMBER}
                            >
                                <RangeRule
                                    min={1}
                                    message="Qty needs to be a positive number."
                                    reevaluate={true}
                                />
                                <PatternRule
                                    pattern={WHOLE_NUMBER_PATTERN}
                                    message="Qty needs to be a whole number."
                                />
                                <RequiredRule message="Qty is required." />
                            </Column>
                            <Column
                                dataField={ASSET_DATA_FIELDS.UNIT_OF_MEASURE_ID}
                                caption="UOM"
                                allowEditing={true}
                            >
                                <Lookup
                                    dataSource={unitOfMeasureStore}
                                    valueExpr="id"
                                    displayExpr="name"
                                />
                            </Column>

                            <Column
                                dataField={ASSET_DATA_FIELDS.YEAR_INSTALLED}
                                dataType="number"
                                caption="Year Installed"
                                format={FORMAT.WHOLE_NUMBER}
                                editorOptions={wholeNumberEditorOptions}
                                setCellValue={setYearInstalledCellValue}
                            >
                                <CustomRule
                                    validationCallback={validateYearIfNotLeased}
                                    message={createRangeRuleMessage(
                                        "Year Installed",
                                        YEAR_RANGE
                                    )}
                                />
                            </Column>

                            <Column
                                dataField={
                                    ASSET_DATA_FIELDS.ASSET_SERVICE_LIFE_ID
                                }
                                caption="Asset Service Life"
                                visible={true}
                                allowEditing={true}
                            >
                                <Lookup
                                    dataSource={assetEntryServiceLifeStore}
                                    valueExpr="id"
                                    displayExpr="name"
                                />
                            </Column>

                            <Column
                                dataField={ASSET_DATA_FIELDS.ASSET_LIFE_CYCLE}
                                dataType="number"
                                caption="Asset Life Cycle"
                                format={FORMAT.WHOLE_NUMBER}
                                editorOptions={wholeNumberEditorOptions}
                                setCellValue={setAssetLifeCycleCellValue}
                            >
                                <AsyncRule
                                    validationCallback={validateLifeCycleAsync}
                                />
                            </Column>
                            <Column
                                dataField={
                                    ASSET_DATA_FIELDS.UNIT_REPLACEMENT_VALUE
                                }
                                caption="Unit Replacement Value"
                                dataType="number"
                                format={FORMAT.THOUSANDS_AND_DECIMAL}
                                editorOptions={thousandsAndDecimalEditorOptions}
                                setCellValue={setUnitReplacmentCellValue}
                            >
                                <CustomRule
                                    validationCallback={
                                        positiveIfNotLeased
                                    }
                                    message="Unit Replacement Value needs to be a positive number."
                                />
                                <CustomRule
                                    validationCallback={requiredIfNotLeased}
                                    message="Unit Replacement Value is required."
                                />
                            </Column>

                            <Column
                                dataField={
                                    ASSET_DATA_FIELDS.AGGREGATE_REPLACEMENT_VALUE
                                }
                                caption="Aggregate Replacement Value"
                                dataType="number"
                                format={FORMAT.THOUSANDS_AND_DECIMAL}
                                editorOptions={thousandsAndDecimalEditorOptions}
                                allowEditing={false}
                            >
                                <CustomRule
                                    validationCallback={
                                        positiveIfNotLeased
                                    }
                                    message="Unit Replacement Value needs to be a positive number."
                                />
                                <CustomRule
                                    validationCallback={requiredIfNotLeased}
                                    message="Unit Replacement Value is required."
                                />
                            </Column>
                            <Column
                                dataField={ASSET_DATA_FIELDS.INITIAL_RY_AUTO}
                                dataType="number"
                                caption="Initial RY Auto"
                                format={FORMAT.WHOLE_NUMBER}
                                editorOptions={wholeNumberEditorOptions}
                                allowEditing={false}
                            >
                                <CustomRule
                                    validationCallback={validateYearIfNotLeased}
                                    message={createRangeRuleMessage(
                                        "Initial RY Auto",
                                        YEAR_RANGE
                                    )}
                                />
                                <CustomRule
                                    validationCallback={requiredIfNotLeased}
                                    message="Initial RY Auto is required."
                                />
                            </Column>
                            <Column
                                dataField={ASSET_DATA_FIELDS.INITIAL_RY_MANUAL}
                                dataType="number"
                                caption="Initial RY Manual"
                                format={FORMAT.WHOLE_NUMBER}
                                editorOptions={wholeNumberEditorOptions}
                                setCellValue={setInitialRYManualValue}
                            >
                                <CustomRule
                                    validationCallback={validateYearIfNotLeased}
                                    message={createRangeRuleMessage(
                                        "Initial RY Manual",
                                        YEAR_RANGE
                                    )}
                                />
                            </Column>

                            <Column
                                dataField={ASSET_DATA_FIELDS.LAST_RY_CYCLE}
                                dataType="number"
                                caption="Last RY Cycle"
                                format={FORMAT.WHOLE_NUMBER}
                                editorOptions={wholeNumberEditorOptions}
                            >
                                <CustomRule
                                    validationCallback={validateYearIfNotLeased}
                                    message={createRangeRuleMessage(
                                        "Last RY Cycle",
                                        YEAR_RANGE
                                    )}
                                    reevaluate={true}
                                />
                                <AsyncRule
                                    validationCallback={
                                        validateLastRYCycleAsync
                                    }
                                    reevaluate={true}
                                />
                            </Column>

                            <Column
                                dataField={ASSET_DATA_FIELDS.LEDGER_ID}
                                caption="Ledger Id"
                            />

                            <Column
                                dataField={ASSET_DATA_FIELDS.NOTES}
                                caption="Notes"
                            />

                            <Column
                                dataField={ASSET_DATA_FIELDS.LEASE_START}
                                dataType="date"
                                caption="Current Lease Term Start"
                                setCellValue={setLeaseStartCellValue}
                            >
                                <CustomRule
                                    validationCallback={requiredIfLeased}
                                    message="Current Lease Term Start is required."
                                />
                            </Column>
                            <Column
                                dataField={ASSET_DATA_FIELDS.LEASE_TERM}
                                dataType="number"
                                caption="Lease Term in Months"
                                format={FORMAT.WHOLE_NUMBER}
                                editorOptions={wholeNumberEditorOptions}
                                setCellValue={setLeaseTermCellValue}
                            >
                                <CustomRule
                                    validationCallback={requiredIfLeased}
                                    message="Lease Term in Months is required."
                                />
                                <RangeRule
                                    min={LEASE_TERM_PARAMS.MIN}
                                    max={LEASE_TERM_PARAMS.MAX}
                                    message={createRangeRuleMessage(
                                        "Lease Term",
                                        LEASE_TERM_PARAMS
                                    )}
                                />
                            </Column>
                            <Column
                                dataField={
                                    ASSET_DATA_FIELDS.LEASE_MONTHLY_PAYMENT
                                }
                                dataType="number"
                                caption="Current Monthly Payment"
                                format={FORMAT.THOUSANDS_AND_DECIMAL}
                                editorOptions={thousandsAndDecimalEditorOptions}
                                setCellValue={setLeaseMonthlyPaymentCellValue}
                            >
                                <CustomRule
                                    validationCallback={requiredIfLeased}
                                    message="Current Monthly Payment is required."
                                />
                                <RangeRule
                                    min={ONE_CENT}
                                    message={`Current Monthly Payment needs to be a positive number greater than ${ONE_CENT}.`}
                                />
                            </Column>
                            <Column
                                dataField={
                                    ASSET_DATA_FIELDS.LEASE_DOLLAR_BUYOUT_AMOUNT
                                }
                                dataType="number"
                                caption="Dollar Buyout Amount"
                                format={FORMAT.THOUSANDS_AND_DECIMAL}
                                editorOptions={thousandsAndDecimalEditorOptions}
                                setCellValue={setDollarBuyoutCellValue}
                            >
                                <RangeRule
                                    min={ONE_CENT}
                                    message="Dollar Buyout Amount needs to be a positive number."
                                />
                            </Column>
                            <Column
                                dataField={ASSET_DATA_FIELDS.LEASE_END}
                                dataType="date"
                                allowEditing={false}
                                caption="Current Lease Term End"
                            />
                            <Column
                                dataField={
                                    ASSET_DATA_FIELDS.LEASE_YEARLY_PAYMENT
                                }
                                dataType="number"
                                allowEditing={false}
                                caption="Total Yearly Lease Payments"
                                format={FORMAT.THOUSANDS_AND_DECIMAL}
                                editorOptions={thousandsAndDecimalEditorOptions}
                            />
                            <Column
                                dataField={
                                    ASSET_DATA_FIELDS.LEASE_TOTAL_PAYMENT
                                }
                                dataType="number"
                                allowEditing={false}
                                caption="Total Lease Payments"
                                format={FORMAT.THOUSANDS_AND_DECIMAL}
                                editorOptions={thousandsAndDecimalEditorOptions}
                            />
                            <Column
                                dataField={ASSET_DATA_FIELDS.IS_LEGACY_LEASE}
                                dataType="boolean"
                                allowEditing={false}
                                visible={false}
                            />
                            <ColumnFixing enabled={true} />
                        </DataGrid>
                    </div>
                </Item>
                <Item visible={processResults}>
                    {processResults && (
                        <ScrollView
                            height={"80vh"}
                            width={400}
                            useNative={true}
                        >
                            <div>{parse(processResults)}</div>
                        </ScrollView>
                    )}
                </Item>
            </Box>
        </div>
    );
};
